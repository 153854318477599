<template>
  <div>
    <div class="mb-10">
      <h5 class="fw-light text-white">
        Quiz Gallery > <span class="fw-bold text-white">index</span>
      </h5>
    </div>
    <h1 class="text-primary quiz-heading mb-14">Gallery view</h1>

    <div
      class="row gap-11 justify-content-center justify-content-md-start"
      style="--bs-gutter-y: 0rem; --bs-gutter-x: 0rem"
    >
      <div
        class="col-sm-12 col-lg-6 col-xxl-4 rounded h-225px h-md-275px h-lg-250px w-md-350px w-lg-275px w-xxl-400px card-width t-selected-outline bg-blue"
      >
        <!-- card overlay container -->
        <div
          class="card-width-overlay d-flex align-items-center justify-content-center"
        >
          <div class="select-button">Select</div>
          <div class="preview-button">Preview</div>
        </div>
        <img
          src="@/assets/gallery/quiz1.png"
          alt=""
          class="image-sizing rounded"
        />
      </div>
      <div
        class="col-sm-12 col-md-6 col-lg-6 col-xl-4 rounded h-225px h-md-275px h-lg-250px w-md-350px w-lg-275px w-xxl-400px card-width"
      >
        <div
          class="card-width-overlay d-flex align-items-center justify-content-center"
        >
          <div class="select-button">Select</div>
          <div class="preview-button">Preview</div>
        </div>
        <img
          src="@/assets/gallery/quiz2.png"
          alt=""
          class="image-sizing rounded"
        />
      </div>
      <div
        class="col-sm-12 col-md-6 col-lg-6 col-xl-4 rounded h-225px h-md-275px h-lg-250px w-md-350px w-lg-275px w-xxl-400px card-width card-width"
      >
        <div
          class="card-width-overlay d-flex align-items-center justify-content-center"
        >
          <div class="select-button">Select</div>
          <div class="preview-button">Preview</div>
        </div>
        <img
          src="@/assets/gallery/quiz3.png"
          alt=""
          class="image-sizing rounded"
        />
      </div>
      <div
        class="col-sm-12 col-md-6 col-lg-6 col-xl-4 rounded h-225px h-md-275px h-lg-250px w-md-350px w-lg-275px w-xxl-400px card-width card-width"
      >
        <div
          class="card-width-overlay d-flex align-items-center justify-content-center"
        >
          <div class="select-button">Select</div>
          <div class="preview-button">Preview</div>
        </div>
        <img
          src="@/assets/gallery/quiz4.png"
          alt=""
          class="image-sizing rounded"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.quiz-heading {
  font-size: 35px !important;
}
.card-width {
  width: 510px;
  position: relative;
  height: 300px;
  cursor: pointer;
  overflow: hidden;
  background: transparent;
  z-index: 1;
}
.t-selected-outline {
  outline: 2px solid #2477ff;
}
.card-width-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  opacity: 0;
  -webkit-transition: z-index 0.3s steps(1), opacity 0.3s, margin-top 0.3s;
  transition: z-index 0.3s steps(1), opacity 0.3s, margin-top 0.3s;
}

.card-width-overlay:hover {
  background: rgba(0, 0, 0, 0.5);

  opacity: 1;
}

.image-sizing {
  object-fit: fill;
  object-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  image-rendering: -webkit-optimize-contrast;
}
.preview-button,
.select-button {
  width: 95px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  line-height: 40px;
  cursor: pointer;
}
.select-button {
  background-color: #2477ff;
  border-radius: 5px;
}
.preview-button {
  border-radius: 5px;
  border: 1px solid #fff;
  margin-left: 15px;
}

@media (min-width: 200px) and (max-width: 1200px) {
  .quiz-heading {
    font-size: 28px !important;
  }
  .quiz-gallary {
    justify-content: center !important;
  }
  .card-width {
    width: 400px;
  }
}
@media (max-width: 1440px) {
  .quiz-heading {
    font-size: 28px !important;
  }
  .quiz-gallary {
    align-items: center;
  }
  .card-width {
    width: 400px;
  }
}
@media (min-width: 1441px) {
  .card-width {
    width: 510px !important;

    height: 300px !important;
  }
}
</style>
